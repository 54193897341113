/*@import 'antd/dist/antd.min.css';*/
/*@import '~antd/dist/antd.css';*/


* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Poppins', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.logo {
  display: flex;
  flex-direction: row;
  height: 32px;
  width: 180px;
  /* margin: 16px; */
  text-align: center;
  background: rgb(255, 255, 255);
}

.collapse-logo {
  height: 32px;
  text-align: center;
}

.custom-select-selector {
  border-radius: 20px 0 0 20px !important;
}

.ant-layout-sider ant-layout-sider-dark ant-layout-sider-has-trigger {
  background-color: white;
}

.side-menu-down-manage-profile {
  position: absolute;
  bottom: 155px;
}

.side-menu-logout {
  position: absolute;
  bottom: 105px;
}

.divider-side-menu {
  position: absolute;
  bottom: 130px;
}

.ant-layout-header {
  padding: 0 17px
}

.ant-layout-sider-trigger {
  border: 1px solid #C0CDD6;
  background-color: #ffffff;
  color: #31a6c7;
}

.ant-layout-sider-children {
  border: 1px solid #C0CDD6;
  background-color: #ffffff;
}

.anticon-apartment {
  color: #31a6c7;
}

.anticon-bell {
  color: #31a6c7;
}

.divider-class {
  margin-top: 7.5px !important;
  margin-bottom: 12.5px !important;
}

.custom-input-style {
  background: #ebebebeb !important;
}

.label {
  font-family: Poppins;
}

.ant-form-item-label {
  display: flex;
}

.submit-button {
  background-color: #31A6C7;
  color: white;
  height: 40px;
  width: 220px;

  border-radius: 21px;
  font-family: Poppins;
  border: none;
}


.auth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding-top: 15vh;
}

.auth-parent-container {
  min-height: 100vh !important;
  width: 100%;
  background: linear-gradient(
          180deg,
          #31A6C7 0%,
          white 66%
  ) !important;

}

.auth-type-title {
  font-family: Poppins;
  text-align: left;
}


.login-container {
  background-color: white;
  height: 65vh;
  padding: 5% 6%;
  box-shadow: 0px 0px 4px #432c2c6a;
  border-radius: 20px;

}


.authLogoContainer {
  text-align: center;
}
.authMainLayout {
  min-height: 100vh !important;
  background-image: url("../public/auth.png");
  background-size: cover;
  padding: 60px;
}

.authContainer {
  background-color: white;
  /*z-index: 99999;*/
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  border-radius: 20px;
  width: 33%;
  padding: 30px;
  border: 2px solid #DADEE2;
}

.authLogoContainer {
  text-align: center;
  /*padding: 15px;*/
}

.welcome_c {
  position: absolute;
  top: 29%;
  left: 39%;
}
.quick_link{
  justify-content: space-between;
  align-content: stretch;
}
@media (max-width : 950px ){
  .authContainer{
    width : 60vw
  }
  .welcome-text{
    font-size: 1.2rem !important;
  }
  .welcome_c {
    position: absolute;
    top: 19%;
    left: 39%;
  }
  .quick_link{
    justify-content:start;
    align-content: stretch;
  }
}
@media (max-width : 950px ){
  .authContainer{
    width : 60vw
  }
  .welcome-text{
    font-size: 1rem !important;

  }
  .formatted-date-text{
    margin-right : 0px;
  }
  .welcome-text-wrapper{
    left: 56% !important;
    top: 30%;
    /* position: relative !important; */
    margin-top: 1px;
    margin-bottom: 1px;
    /* width: 80% !important; */
  }

  .user-img-dashboard{
    height: 60% !important;
    top: 50px
  }
}

@media (max-width: 575px) {
  .authMainLayout {
    /*background-color: white !important;*/
    padding: 15px;
  }

  .authContainer {
    width: 95%;
    margin-top: 0px;
    box-shadow: none !important;
    position: absolute;
    top: 50;
  }

  .authMainLayout{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btnFloatRight {
    float: left;
  }
}

/* *::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}
 */
/* *::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
} */

*::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #5a5a5a;
}

#components-form-demo-normal-login .login-form {
  max-width: 300px;
}

#components-form-demo-normal-login .login-form-forgot {
  float: right;
}

#components-form-demo-normal-login .ant-col-rtl .login-form-forgot {
  float: left;
}

#components-form-demo-normal-login .login-form-button {
  width: 100%;
}

@media (max-width: 575px) {
  #components-form-demo-normal-login .login-form-forgot {
    padding: 0;
  }
}


.login-form-button {
  width: 100%;
  background-color: #31A6C7;
  color: white;
  height: 40px;

  border-radius: 21px;
  font-family: Poppins;
  border: none;
}

@media only screen and (max-width: 600px) {
  .submit-button {
    background-color: #31A6C7;
    width: 185px;
    color: white;
    border-radius: 21px;
    font-size: 1em;
    height: 40px;
  }
}

@media only screen and (max-width: 600px) {
  .auth-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding-top: 12vh;
  }
}


/*search Header UI*/
.search-header {

}


.search-header .category-search {
  width: 35%;
}

.search-header .category-search:hover {
  border: none #ffffff;
}

.search-header .category-search .ant-select-selector {
  border-radius: 20px 0px 0px 20px !important;

  background-color: #ebebeb;
  height: 36px !important;
  opacity: 1;
  padding-top: 2px !important;
}

.search-header .input-search {
  width: 65%;
  border-radius: 20px;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  background-color: #ebebeb;
  opacity: 1;
  height: 36px;
}

.search-header .input-search > .ant-input {
  background-color: #ebebeb;
  opacity: 1;
}

.other-form-input > .ant-input {
  border: 1px dashed #DADEE2 !important;
}

.search-header-btn {
  background-color: #31a6c7;
  color: #ffff;
  border: none;
  font-family: 'Poppins';
  width: 109px;
  /*height: 40px;*/
}

.search-header-btn:hover, .search-header-btn:focus, .search-header-btn:active {
  background-color: #31a6c7;
  color: #ffff;
  border: none;
  font-family: 'Poppins';
  width: 109px;
}

.search-radius {
  border-radius: 20px;
}

/*view section*/
.view-heading {
  padding-bottom: 20px;
}

.view-heading .view-title {
  color: #31a6c7 !important;
  font-family: Poppins;
  font-size: 19px !important;
  font-weight: 600 !important;
}

.search-input {
  background-color: #F6F6F6;
  border: none;
}

.search-input .ant-input {
  background-color: #F6F6F6;
  border-radius: 5px;
}

.employee-img {
  border-radius: 50%;
  width: 181px;
  height: 181px;
}

.employee-form .ant-input {
  background: #ffffff;
  border-radius: 20px;
}

.page-main-heading {
  color: #31a6c7 !important;
  font-size: 19px !important;
  font-family: Poppins !important;
  font-weight: 600 !important;
  border-radius: 5px !important;
}

/*.form-label-font {*/
/*    font-size: 14px;*/
/*    font-weight: 600;*/
/*    color: #343434;*/
/*}*/

.radio-button-label {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  color: #4D6072;
}

.primary-btn {
  background-color: #31A6C7;
  color: white;
  border: none;
  text-align: center !important;
  border-radius: 20px !important;

}

.active-service-tab {
  background-color: #31A6C7 !important;
  color: white !important;
}

.btn-border-radius {
  border-radius: 18px !important;
  height: 35px !important;
  width: 85px !important;
}

.primary-btn:hover {
  background-color: #31A6C7;
  color: white;
  border: none;
}

.primary-btn:focus {
  background-color: #31A6C7;
  color: white;
  border: none;
}

.secondary-btn {
  background-color: #4D6072;
  color: white;
  border: none;
  border-radius: 20px !important;
  width: 145px;
}

.secondary-btn:hover {
  background-color: #4D6072;
  color: white;
  border: none;
}

.secondary-btn:focus {
  background-color: #4D6072;
  color: white;
  border: none;
}

.search-custom-option > .ant-select-selector {
  background-color: #0000000D !important;
  border-radius: 5px;
}

.heading-format-one {
  font-size: 19px;
  font-family: Poppins;
  color: #31A6C7;
  font-weight: 600;
}

.gray-button {
  background-color: #F3F4F7;
  color: #636D77;
  border-radius: 5px;
}

.default-form-input-format {
  width: 100%;
  border-radius: 5px;
  font-family: Poppins;
}

.default-form-text-area-format {
  width: 100%;
  border-radius: 5px;
  font-family: Poppins;
  height: 95px !important;
}


.other-service-card-dimension {
  width: 190px;
  height: 260px;

}

.other-service-card-title {
  color: #31A6C7;
  font-size: 12px;
  font-family: Poppins;
  font-weight: 600;
  text-align: center;
}

.default-rich-text-editor {
  border: 2px solid black
}

.email-btn {
  width: 148px;
  height: 33px;
  border-radius: 17px !important;
}

/*community*/
.task-footer {
  background: #F4F4F5 0% 0% no-repeat padding-box;
  border: 0.800000011920929px solid #DADEE2;
  opacity: 1;
}

.post-padding {
  padding-left: 16px;
  padding-right: 16px;
}

.post-header {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.task-footer .features {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 2px 0px;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer !important;
}

.task-footer .features > span {
  padding-left: 4px;
}

.current-credit {
  background: #F4F4F5 0% 0% no-repeat padding-box;
  border: 1px solid #DADEE2;
  border-radius: 20px;
  width: 120px;
  text-align: center;
  align-items: end;
  display: flex;
  justify-content: space-around;
  padding: 4px 10px;
}

.current-credit .value {
  font-weight: 600;
  color: #343434;
  font-size: 16px;
  line-height: 25px;
  padding-right: 8px;
}

.current-credit .icon {
  color: #FEDA2C;
}

.table-card {
  border: none;

}

.table-card .ant-card-body {
  padding: 8px 16px;
  box-shadow: 0px 0px 5px #00000029;
  border-radius: 5px;
}

.credit {
  color: #31A6C7;
}

.debit {
  color: #E05555;
}

.community-search .ant-input-affix-wrapper {
  border-radius: 20px 0px 0px 20px !important;
  background-color: #fff !important;
}

.community-search-options {
  padding-right: 6px;
  display: flex;
  align-items: center;
}

.org-list .ant-select-selector {
  border-radius: 20px !important;
}
.search-customer .ant-select-selector {
  border-radius: 20px !important;
  background-color: #ebebeb !important;
}

.dashboard-monthlyfilter .ant-select-selector{
  background-color: #F4F4F5 !important;
  border-radius: 5px;
}

.edit-profile-img {
  color: #4D6072;
  font-size: 16px;

}

.edit-profile-img > .divider {
  border-width: 2px;
  border-color: #4D6072;
}

.app-sider-menu {
  font-size: 14px;
  color: #343434;
  font-weight: 500;
}

.interestedList {
  border-bottom: 0px !important;
  padding: 4px 0px !important;
}

.radio-list {
  width: 100%;
  padding: 6px 12px;
  border-radius: 5px !important;
}

.chat-divider > .ant-divider > .ant-divider-horizontal {
  margin: 14px !important
}

.message-bubble {
  display: inline-block;
  padding: 10px;
  border-radius: 10px;
  max-width: 95%;
}

.receiver {
  background: #F6F6F6 !important;
  float: left;
}

.receiver .message-text {
  color: #4D6072;
}

.sender {
  background-color: #C0CDD6 !important;
  float: right;
  margin-right: 12px;
}

.sender .message-text {
  color: #4D6072;
}

.message-text {
  margin-bottom: 5px;
}

.transfer-task-btn {
  width: 140px;
  height: 32px;
}

.remove-list-border {
  border-bottom: none !important;
}

.chatbox-input {
  height: 32px;
  margin-top: 30px;
}

.active-chat {
  font-size: 16px;
  font-weight: 500;
  color: #31A6C7;
}

.chat-name {
  font-size: 16px;
  font-weight: 500;
  color: #4D6072;
}

.selected-chat-circle {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  color: #31A6C7;
}

.chat-message-display {
  color: #4D6072;
  font-size: 10px;
  font-weight: 500;
}

.disabled{
  cursor:none !important;
  opacity: 0.5;
}

.disabled-features{
  pointer-events: none !important;
  cursor: not-allowed !important;
  opacity: 0.5;
}
.doc-para {
  font-size: 14px !important;
  line-height: 21px !important;
  color: #4D6072 !important;
}
.my-upload .ant-upload-list-item-name {
  display: none;
}

.my-upload .ant-upload-list-item-actions {
  display: none;
}

.my-upload .ant-upload-list-item-info{
  display: none;
}

.custom-form-item-height{
  height : 25px;
}

.ant-upload-list-item{
  display: none !important;
}

.my-editor{
  border-radius: 10px;
  border: 1px solid rgb(218, 222, 226);
}
.status-btn>button:first-child{
  width: inherit !important;
}

.notification-item {
  transition: background-color 0.6s;
}

.notification-item:hover {
  background-color: #f0f0f0;
}

.welcome-text{
  font-size: 1.8rem;
  font-weight: 600;
}

.order-card-content-number{
  color : #363565;
  font-size: 1.8rem !important;
  font-weight: 800;
  text-align: center;
  font-family: Nunito Sans, Sans Serif;
}

.quick-link-card {
  transition: transform 0.3s, box-shadow 0.3s;
  border-radius: 5px;
}

.quick-link-card:hover {
  transform: scale(1.06);
  box-shadow:0px 13px 32px #00000024;
  cursor: pointer;
}

.rounded-checkbox .ant-checkbox-inner {
  border-radius: 50%; /* Make the checkbox rounded */
}

.rounded-checkbox .ant-checkbox-checked .ant-checkbox-inner::after {
  border-radius: 50%; /* Make the checked indicator rounded */
}



.user-img-dashboard{
  height: 100%;
}

.formatted-date-text{
  margin-right : 245px;
}
.company-text{
  display: flex;
  /*justify-content: center;*/
  /*margin-right: 125px;*/
}

.range-picker > .ant-picker-input > input {
  font-size: 0.7em !important;
}

.community-range-picker > .ant-picker-input > input {
  font-size: 0.8em !important;
}